import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Tacho } from "./elements/Tacho.svg";
import { ReactComponent as ArrowRight } from "./elements/rehub_socrates_arrow_right_la1.svg";
import { jumpToElementByClass } from "./Util";
import AlertError from "./AlertError";
import SuccessDialog from "./SuccessDialog";
import "./Email.css";

function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

/**
 * Get the result of the requested function.
 * @param {String} str Function name
 * @param {String} obj Desired object name
 * @param {Function} func What to do with the resulting object
 */
async function getResultFunction(str, obj, func) {
  try {
    fetch("/retrieve.php", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        function: str,
      }),
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          func(res.data[obj]);
        })
    );
  } catch (error) {
    console.error(error);
  }
}

/**
 * Get the amount of signees as integer
 * @param {Function} func What to do with the resulting object
 */
async function getAmountOfSignees(func) {
  getResultFunction("amount_of_signees", "amount", func);
}

function decode_c(input) {
  //input  ="https://socrates.city/?s=2&m=exists_in_db&c=amFuLmJhdGVsa2FAZ21haWwuY29tO2JhdGVsa2E7amFuO3JlaHViIGZvcmdlOzI%3D"
  let key = "c";
  let split = input.split("/");
  let search = split[split.length - 1];
  let urlParams = new URLSearchParams(search);
  let param = urlParams.get(key);

  if (param === null) {
    return [];
  }

  let result = b64_to_utf8(param);
  console.log(result);
  return result.split(";");
}

function Email() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [policyCheckbox, setPolicyCheckbox] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSubmit = (e) => {
    // console.log(policyCheckbox);
    // console.log("handle submitttt");
  };

  useEffect(() => {
    // Do something with the total amount of signees
    getAmountOfSignees((res) => {
      console.log("Amount: " + res);
    });

    let fields = decode_c(window.location.search);

    if (fields.length > 0) {
      var text = "";

      switch (parseInt(fields[0])) {
        case 0:
          text = t("notify-error-message");
          switch (fields[2]) {
            case "oh_dear_robot":
              text += t("notify-error-botused");
              break;
            case "email_missing":
              text += t("notify-error-emailmissing");
              break;
            case "name_missing":
              text += t("notify-error-namemissing");
              break;
            case "surname_missing":
              text += t("notify-error-surnamemissing");
              break;
            case "company_missing":
              text += t("notify-error-companymissing");
              break;
            case "email_malformed":
              text += t("notify-error-emailnotvalid");
              break;
            case "db_error":
              text += t("notify-error-dbfailure");
              break;
            default:
              text += t("notify-error-unknown");
          }
          setMessage(<AlertError text={text}></AlertError>);
          break;
        case 1:
          text = fields[5] + t("notify-tankyou");
          setMessage(<SuccessDialog text={text}></SuccessDialog>);
          break;
        case 2:
          text =
            t("notify-allreadyknown-1") +
            fields[3] +
            t("notify-allreadyknown-2");
          setMessage(<SuccessDialog text={text}></SuccessDialog>);
          break;
      }

      setFirstname(fields[5]);
      setLastname(fields[4]);
      setCompany(fields[6]);
      setEmail(fields[3]);

      i18n.changeLanguage(fields[1]);

      jumpToElementByClass("email-registration");
    }
  }, []);

  return (
    <>
      <div className="email-registration">
        <div className="email-title">
          <h1>{t("email-1")}</h1>
          <h1 className="early-access">{t("email-2")}</h1>
          <h2>
            <i>beta</i> SOCRATES 1.7
          </h2>
          <div className="tacho-container">
            <div className="tacho">
              <Tacho className="tacho-graphic" />
              <h2>65%</h2>
              <p>{t("email-4")}</p>
            </div>
          </div>
        </div>
        <div className="alert-box">{message}</div>
        <div className="form-container">
          <p className="title-form">{t("email-5")}</p>
          <form
            className="form"
            action="/input.php"
            method="post"
            onSubmit={handleSubmit}
          >
            <div className="form-names">
              <input
                id="name"
                className="name"
                type="text"
                name="name"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <input
                id="surname"
                className="surname"
                type="text"
                name="surname"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </div>
            <input
              id="company"
              className="company"
              type="text"
              name="company"
              placeholder="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <input
              id="email"
              className="email"
              type="email"
              name="email"
              placeholder="E-Mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <li>
              <input
                type="checkbox"
                id="policy"
                name="Policy"
                checked={policyCheckbox}
                onChange={(e) => {
                  setPolicyCheckbox(e.target.checked);
                }}
              />
              <label htmlFor="policy">
                <a href="/#/policy" target="_blank">
                  {t("email-6")}
                </a>
              </label>
            </li>
            {/* <input type="submit" value="Sign in"/> */}
            <button disabled={!policyCheckbox} type="submit">
              {t("signin-btn")} <i>beta</i> SOCRATES 1.7
            </button>
          </form>
          <table>
            <tbody>
              <tr className="row">
                <td>
                  <ArrowRight className="arrow-right" /> {t("email-7")}
                </td>
                <td>
                  <ArrowRight className="arrow-right" /> {t("email-8")}
                </td>
              </tr>
              <tr className="row">
                <td>
                  <ArrowRight className="arrow-right" /> {t("email-9")}
                </td>
                <td>
                  <ArrowRight className="arrow-right" /> {t("email-10")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* <LoadingBar percentage = {100}></LoadingBar> */}
    </>
  );
}

function LoadingBar(props) {
  let percentage = Number(props.percentage) * 2 - 150;
  return (
    <>
      <div
        className="quarter-circle"
        style={{ transform: "rotate(" + percentage + "grad)" }}
      ></div>
      <div className="stencil"></div>
      <div className="stencil2"></div>
      {props.percentage} %
    </>
  );
}

export default Email;
