import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconLetter } from "./elements/icon_letter.svg";
import { ReactComponent as IconLinkedin } from "./elements/icon_linkedin.svg";
import { ReactComponent as RehubForgeLogo } from "./elements/REHUB_FORGE_Logo_RGB_white_RZ.svg";
import ShareButtonFooter from "./ShareButtonFooter";
import "./Contact.css";

function Contact() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="contact">
                <div className="contact-info">
                    <div className="contact-container">
                        <p>{t("footer-1")} <b>{t("footer-2")}</b></p>
                        <div className="mail-container">
                            <IconLetter className="mail" />
                            <a href="mailto:socrates@rehub.team">socrates@rehub.team</a>
                        </div>
                        <div className="social-media">
                            <p><b> {t("footer-3")} </b></p>
                            <div className="linkedin-container">
                                <a href="https://www.linkedin.com/company/rehubforge/" target="_blank">
                                    <IconLinkedin className="linkedin-icon" />
                                </a>
                                <a href="https://www.linkedin.com/company/rehubforge/" target="_blank">REHUB FORGE</a>
                            </div>
                        </div>
                        <h4><i>#forgingfuture</i></h4>
                    </div>
                    <div className="speech-bubble">
                        <img src="/images/footer/icon_speechbubble.png" />
                    </div>
                </div>
                <footer>
                    <div className="legal-pages">
                        <h5>©  2022 REHUB FORGE GmbH | SOCRATES</h5>
                        <a href="/#/imprint" target="_blank">
                            {t("footer-4")}
                        </a>
                        <a href="/#/policy" target="_blank">
                            {t("footer-5")}
                        </a>
                    </div>
                    <div className="footer-second-container">
                        <ShareButtonFooter className="share-button" />
                        <div className="product-by-rehub">
                            <h5>{t("footer-6")}</h5>
                            <RehubForgeLogo className="rehub-forge-logo" />
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Contact;