import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Carousel from 'react-bootstrap/Carousel';
import { Button, Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import 'animate.css';

import Modul1 from "./Modul1";
import "./Video.css";
import { VideoContext } from "./video-context";

function Video() {
  const [index, setIndex] = useState(0);
  const { t, i18n } = useTranslation();

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const ref = useRef(null);

  const onPrevClick = () => {
    ref.current.prev();
  };
  const onNextClick = () => {
    ref.current.next();
  };

  function PrevNextButtons(props) {
    return (
      <div className="button-container">
        <Button className="carousel-control-prev-icon" variant="primary" onClick={props.onPrevClick}>
        </Button>
        <Button className="carousel-control-next-icon" variant="primary" onClick={props.onNextClick}>
        </Button>
      </div>
    )
  }

  function MappedCarousel() {
    const values = [[1, "/images/carousel/SolarPan_v01(0.7).mp4"],
    [2, "/images/carousel/WF_03F(x1.5).mp4"],
    [3, "/images/carousel/WF_01F(x2).mp4"]];
    const items = values.map(function (sublist) {
      var i = sublist[0];
      var video_name = sublist[1];
      return (
        <Carousel.Item>
          <Container>
            <Row>
              <Col>
                <video
                  className="carousel-video"
                  src={video_name}
                  muted
                  autoPlay
                  playsInline
                  loop
                  alt="All the devices"
                />

              </Col>
              <Col className="second-col">
                <PrevNextButtons onPrevClick={() => { onPrevClick() }} onNextClick={() => { onNextClick() }}></PrevNextButtons>

                <div className="side-text">
                  <h3>{t("video-side-main-" + i + "")}</h3>
                  <h4>{t("video-side-sub-" + i + "")}</h4>
                  <ul>
                    <li>{t("video-side-bulletpoint-" + i + ".1")}</li>
                    <li>{t("video-side-bulletpoint-" + i + ".2")}</li>
                    <li>{t("video-side-bulletpoint-" + i + ".3")}</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      )
    }
    );
    return (
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}
        slide={false} fade={true}
        controls={false}
        indicators={false}
        ref={ref}
      >
        {items}

      </Carousel>
    )
  }

  return (
    <>
      <VideoContext.Provider value={index}>
        <div className="video-container">
          <Modul1></Modul1>
          <MappedCarousel></MappedCarousel>

        </div>
      </VideoContext.Provider>
    </>
  );
}

export default Video;