import React  from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "./elements/rehub_socrates_arrow_right_la1.svg";
import "./Mission.css";

function Modul4() {
    const { t, i18n } = useTranslation();

    return (
        <div>            
            <div className="mission">
                <div className="mission-img">
                    <ArrowRight className="arrow-mission" />
                </div>
                <div className="mission-txt">
                    <h1>{t("mission-1")}</h1>
                    <h1 className="mission-bold">{t("mission-2")}</h1>
                    <p>{t("mission-3")}</p>
                </div>          

            </div>
        </div>
    );
}

export default Modul4;