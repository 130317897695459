import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';
import "./SocialIcon.css";

function SocialLinkedin() {
  
    const { t, i18n } = useTranslation();
    const handleClick = () => {
        var url="https://www.linkedin.com/shareArticle?mini=true";
        url+="&url=" + encodeURI(t('socialicons-linkedin-url'));
        url+="&title=" + encodeURI(t('socialicons-linkedin-title'));
        url+="&summary=" + encodeURI(t('socialicons-linkedin-summary'));
        url+="&source=" + encodeURI(t('socialicons-linkedin-source'));
        window.open(url, '_blank').focus();
    }
  
    return (
      <Button variant="primary" onClick={handleClick}>
        <div className="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--medium">
            <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solidcircle">               
                <img src="images/share-btn/icon_awesome-linkedin-in.png" />
            </div>
            LINKEDIN
        </div>
      </Button>
    );
}
  
export default SocialLinkedin;