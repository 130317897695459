import React, {useState, useEffect, useCallback} from "react";
import { useTranslation } from "react-i18next";
import {VideoContext} from './video-context'
import "./Modul1.css";

const specialWords = ["slogan-fillin-1", "slogan-fillin-2", "slogan-fillin-3"];

function Modul1() {
    const [word, setWord] = useState();
    const { t, i18n } = useTranslation();

    //Timer Stuff for self-replacing words: (not localized yet, TODO!)
    // const [specialWordIndex, setSpecialWordIndex] = useState(0);  // initialize index to 0
    // const specialWord = useState(["slogan-fillin-1", "slogan-fillin-2", "slogan-fillin-3"]);

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * specialWords.length);
        // console.log(index)
        // console.log(specialWords[index])
        setWord(specialWords[index]);
    }, []);


    useEffect(() => {
        const intervalID = setInterval(shuffle, 2000);
        return () => clearInterval(intervalID);
    }, [shuffle])

    // const videoIndex = useContext(VideoContext);

    return (
        <div className="slogan-container">            
            <div className="slogan">
                <h2>SOCRATES, </h2>
                <h2>{t("slogan-construct")}</h2><br/>
                <h2>{t("slogan-construct2")}</h2><br/>
                <h1 className="gradient-txt">{t(word)}</h1>

            </div>
        </div>
    );
}

export default Modul1;