import React, { useState } from "react";
import { Alert } from 'react-bootstrap';
import "./AlertError.css";
import { useTranslation } from "react-i18next";

function AlertError(props) {
    const [show, setShow] = useState(true);
    const { t, i18n } = useTranslation();

    if (show) {
        return (
            <Alert variant="danger" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{t("alerterror-error-message")}</Alert.Heading>
                <p>{props.text}</p>
            </Alert>
        );
    }
}

export default AlertError;