import React from "react";
import { useTranslation } from "react-i18next";
import Contact from "./Contact";
import './PrivacyPolicy.css';

function Policy() {
    const { t, i18n } = useTranslation();

    return (
        <>
            <div className="policy">
                <h3>REHUB FORGE GmbH<br />
                    <br />
                    Datenschutzerklärung
                </h3>
                <br />
                <br />
                <p>
                    Die REHUB FORGE GmbH nimmt den Schutz Ihrer personenbezogenen Daten ernst. Die strikte Einhaltung der Vorgaben der DSGVO (Datenschutzgrundverordnung)
                    gehört zu den wichtigen Unternehmenszielen der CG Elementum GmbH. In dieser Daten-schutzerklärung erläutern wir, welche Art von persönlichen Informationen
                    wir erfassen und auf welche Weise wir diese Informationen verwenden, offenlegen und schützen.
                </p>
                <p>
                    Dabei handelt es sich ausschließlich um Daten im Zusammenhang mit dem Besuch unserer Internetseite <a href="https://socrates.city/" target="_blank">https://socrates.city/</a>  (nachfolgend „Homepage“) und der
                    Inan-spruchnahme der dort verfügbaren Leistungen der REHUB FORGE GmbH.
                </p>
                <p>
                    Bei der Erhebung, Verarbeitung und Nutzung Ihrer Daten beachten wir selbstverständ-lich die geltenden datenschutzrechtlichen Vorschriften, insbesondere die Vorschriften des Datenschutzgesetzes und des
                    Telemediengesetzes.
                </p>
                <p>
                    Wir behalten uns vor, den Inhalt dieser Datenschutzerklärung anzupassen. Wir empfeh-len Ihnen daher höflich, die Datenschutzerklärung in regelmäßigen Abständen erneut zur Kenntnis zu nehmen.
                </p>
                <br />
                <p><b>Welche Daten erfassen wir?</b></p>
                <p>
                    In dieser Datenschutzerklärung bedeutet „persönliche Informationen“ Daten und Infor-mationen, mit denen Sie identifiziert werden könnten. Dazu zählen normalerweise In-formationen wie z.B. Ihr Name,
                    Ihre Adresse, Ihre Emailadresse und Telefonnummer, dies kann auch andere Informationen wie z.B. Ihre IP-Adresse, Kaufgewohnheiten oder Ihre Vorlieben und Interessen umfassen.
                </p>
                <p>
                    Beispielsweise übermittelt Ihr Internetbrowser beim Zugriff auf unsere Website aus tech-nischen Gründen automatisch Datum und Uhrzeit des Zugriffs, URL der verweisenden Website, aufgerufene Datei,
                    Menge der gesammelten Daten sowie die IP-Adresse. Diese Daten werden getrennt von anderen Dateien, die Sie im Rahmen der Nutzung unserer Homepage übermitteln. Die Zuweisung zu einer bestimmten Person
                    ist uns nicht mög-lich. Die vorstehenden Daten werden allenfalls zu statistischen Zwecken ausgewertet, um das Nutzungsverhalten kennenzulernen und unseren Internetauftritt für Sie weiter zu verbessern.
                    Eine Weitergabe an Dritte findet weder zu kommerziellen, noch zu nicht kommerziellen Zwecken statt.
                </p>
                <p>
                    Sie können unsere Online-Präsentation grundsätzlich ohne Offenlegung Ihrer persönli-chen Daten nutzen. Durch das Aufrufen unserer Website werden auf unseren Servern Daten für Sicherungszwecke gespeichert,
                    wie oben erläutert. Diese Daten würden mög-licherweise eine Identifizierung zulassen, allerdings findet keine personenbezogene Verwertung statt. Die hierbei gewonnenen Daten werden ausschließlich statistisch
                    und nicht auf Sie zurückführbar ausgewertet.
                </p>
                <br />
                <p><b>Erhebung und Verarbeitung persönlicher Daten</b></p>
                <p>
                    Persönliche Daten, wie z.B. Name oder Anschrift, werden nur dann erhoben, wenn Sie aktiv direkt mit uns Kontakt aufnehmen, z.B. per Email oder über unser Onlineformular.
                </p>
                <p>
                    Soweit Sie unsere Emailformulare nutzen, um mit uns Kontakt aufzunehmen, werden die eingegebenen Daten hierbei von Ihrem PC aus nach neuestem technischen Stan-dard (SSL) verschlüsselt übertragen, um sie gegen etwaige
                    missbräuchliche Nutzung von dritter Seite zu schützen.
                </p>
                <br />
                <p><b>Wofür nutzen wir Ihre persönlichen Informationen?</b></p>
                <p>
                    Verwendungszweck für Ihre persönlichen Daten, die Sie uns freiwillig zur Verfügung stellen, ist ausschließlich der Zweck der Kontaktaufnahme und zur Beantwortung Ihrer Fragen. Vorstehendes gilt im Übrigen nur innerhalb
                    der REHUB FORGE GmbH. An-sonsten ist eine Weitergabe Ihrer Daten an Dritte ausgeschlossen, sofern nicht Ihr aus-drückliches Einverständnis hierzu eingeholt wurde bzw. Sie dies erklärt haben. Ausge-nommen hiervon sind bestehende
                    gesetzliche Verpflichtungen. Beispielsweise können Ihre persönlichen Informationen für folgende Zwecke verwandt werden:
                </p>
                <ul>
                    <li>
                        Zur Bereitstellung unserer Produkte und Dienstleistungen: Verbesserung unserer Produkte und Dienstleistungen sowie Ihre Erfahrungen auf unserer Homepage und Lieferung der Produkte und Services, die Sie von uns wünschen;
                    </li>
                    <li>
                        Kontaktaufnahme mit Ihnen in Bezug auf Produkte und Dienstleistungen (z.B. Informationen zu Angeboten oder Veranstaltungen), die von Interesse für Sie sein könnten, vorausgesetzt, Sie haben uns die Einwilligung dazu erteilt oder
                        Sie ha-ben vorher ein Produkt oder eine Dienstleistung bei uns angefragt und die Kom-munikation ist relevant für diese vorherige Anfrage oder steht in Verbindung mit ihr und erfolgt innerhalb eines Zeitrahmens, der von geltenden
                        Gesetzen festge-legt ist;
                    </li>
                </ul>
                <br />
                <p><b>Web-Analyse</b></p>
                <br />
                <p><b>Google Tag Manager</b></p>
                <p>
                    Wir setzen den Google Tag Manager ein. Anbieter ist die Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
                </p>
                <p>
                    Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir Tracking- oder Statistik-Tools und andere Technologien auf unserer Website einbinden können. Der Google Tag Ma-nager selbst erstellt keine Nutzerprofile, speichert keine Cookies
                    und nimmt keine eigen-ständigen Analysen vor. Er dient lediglich der Verwaltung und Ausspielung der über ihn eingebundenen Tools. Der Google Tag Manager erfasst jedoch Ihre IP-Adresse, die auch an das Mutterunternehmen von Google in die
                    Vereinigten Staaten übertragen werden kann.
                </p>
                <p>
                    Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSG-VO. Der Websitebetreiber hat ein berechtigtes Interesse an einer schnellen und unkom-plizierten Einbindung und Verwaltung verschiedener Tools auf seiner
                    Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließ-lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerruf-bar.
                </p>
                <br />
                <p><b>Google Analytics</b></p>
                <p>
                    Diese Website benutzt Google Analytics, ein Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sogenannte „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website
                    durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung
                    auf dieser Website wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union und in anderen Vertragsstaaten des Abkommens über den Europäischen Wirt-schaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die
                    volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
                    zusammenzustellen und um wei-tere, mit der Websitenutzung und der Internetnutzung verbundenen Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
                    anderen Dateien von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
                    Funktionen dieser Website vollum-fänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch die Cookies erzeugten und auf Ihre Nutzung der Website bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google
                    verhindern, indem Sie das unter dem folgenden Link <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">(http://tools.google.com/dlpage/gaoptout?hl=de)</a> verfügbare Brow-ser-Plugin herunterladen und installieren.
                </p>
                <br />
                <p><b>Minderjährigenschutz</b></p>
                <p>
                    Unsere Internetseiten sind für die Nutzung durch Erwachsene konzipiert und vorgese-hen. Personen unter 18 Jahren sollten ohne Zustimmung der Erziehungsberechtigten keine personenbezogenen Daten an uns übermitteln. Wir fordern keine personenbezo-genen
                    Daten von Kindern und Jugendlichen an.
                </p>
                <br />
                <p><b>Sicherheit</b></p>
                <p>
                    Wir ergreifen alle zumutbaren Vorkehrungen, um Ihre persönlichen Informationen sicher aufzubewahren und verlangen von jedem Dritten, der Ihre persönlichen Informationen für uns handhabt oder verarbeitet, dies ebenfalls zu tun. Der Zugriff auf Ihre persönlichen
                    Informationen ist eingeschränkt, um den unbefugten Zugriff auf sie, deren Ände-rung oder Missbrauch zu verhindern. Unsere Mitarbeiter und für uns Tätige dürfen nur in dem Maße auf sie zugreifen, wie es für die Erledigung ihrer Aufgaben nötig ist.
                </p>
                <br />
                <p><b>Löschung von personenbezogenen Daten</b></p>
                <p>
                    Personenbezogene Daten werden unverzüglich gelöscht, soweit sie für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig sind.
                </p>
                <br />
                <p><b>Ihre Rechte</b></p>
                <p>Sie haben das Recht uns mitzuteilen, dass Sie:</p>
                <ul>
                    <li>zukünftig nicht von uns kontaktiert werden möchten;</li>
                    <li>eine Kopie der persönlichen Informationen erhalten möchten, die wir über Sie haben;</li>
                    <li>wünschen, dass wir Ihre persönlichen Informationen in unseren Aufzeichnungen korrigieren, aktualisieren oder löschen;</li>
                    <li>einen Missbrauch Ihrer persönlichen Informationen melden möchten.</li>
                </ul>
                <br />
                <p><b>Links</b></p>
                <p>
                    Für Inhalte anderer Websites, auf die Sie durch einen Link von unserer Website gelan-gen können, können wir keine Verantwortung übernehmen.
                </p>
                <br />
                <p><b>Kontaktaufnahme</b></p>
                <p>
                    Wenn Sie Fragen oder Anmerkungen zum Datenschutz bei der REHUB FORGE GmbH haben, wenden Sie sich bitte an unseren Datenschutzbeauftragten:<br />
                    <br />
                    Herrn Rechtsanwalt Klaus Voigt, Augustusweg 105, 01445 Radebeul Telefon: 0351/837890, Telefax: 0351/8378930, Email: <a href="mailto:voigt@raevoigt.de">voigt@raevoigt.de</a>

                </p>
                <br />
                <p><b>Änderung der Datenschutzerklärung der REHUB FORGE GmbH</b></p>
                <p>
                    Im Zuge der Weiterentwicklung unserer Internetseiten und der Implementierung neuer Technologien, um unseren Service für Sie zu verbessern, können auch Änderungen dieser Datenschutzerklärung erforderlich werden. Wir halten Sie diesbezüglich jeweils auf
                    dem aktuellen Stand.
                </p>
                <br />
                <br />
                <p>letzte Aktualisierung: 29.07.2022</p>
                <br />
                <p>
                    REHUB FORGE GmbH<br />
                    Deutz-Mühlheimer Straße 121<br />
                    51063 Köln<br />
                    <a href="https://www.rehub-forge.team/" target="_blank">www.rehub-forge.team</a><br />
                    mail: <a href="mailto:marketing@rehub.team">marketing@rehub.team</a><br />
                    HR B 110294 Amtsgericht Köln<br />
                    Geschäftsführung: Timo Schroeder, Jörg Dietz<br />
                </p>
            </div>
            <Contact />
        </>
    );
}

export default Policy;