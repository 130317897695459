import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationDE from "./locals/de/translationDE.json";
import translationEN from "./locals/en/translationEN.json";

//the translations
const resources = {
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "de",

    keySeparator: false,

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;