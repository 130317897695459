import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';
import "./SocialIcon.css";

function SocialTelegram() {
  
    const { t, i18n } = useTranslation();
    const handleClick = () => {
        var url="https://telegram.me/share/url";
        url+="?text=" + encodeURI(t('socialicons-telegram-text'));
        url+="&url=" + encodeURI(t('socialicons-telegram-url'));
        window.open(url, '_blank').focus();
    }
  
    return (
      <Button variant="primary" onClick={handleClick}>
        <div className="resp-sharing-button resp-sharing-button--telegram resp-sharing-button--medium">
            <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solidcircle">
            <img src="images/share-btn/icon_awesome-telegram-plane.png" />
            </div>
            TELEGRAM
        </div>
      </Button>
    );
}
  
export default SocialTelegram;