import React from 'react';
import Header from "./components/Header";
import FrontPage from "./components/FrontPage";
import Modul1 from './components/Modul1';
import VideoAndText from './components/VideoAndText';
import Video from "./components/Video";
import Email from "./components/Email";
import Mission from './components/Mission';
import Contact from "./components/Contact";
import TagManager from 'react-gtm-module';
import CookieBot from 'react-cookiebot/lib/CookieBot';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const { domainGroupId }= require('./secrets.json'); 


const tagManagerArgs = {
  gtmId: 'GTM-KNPSV5K'
}

TagManager.initialize(tagManagerArgs)

function App() {
  console.log("width: " + window.screen.width, "height: " + window.screen.height);
  console.log(domainGroupId);
  return (
    <div id="app-container" className="App">
      <Header />
      <div id="content">  
        <FrontPage />
        <CookieBot domainGroupId={domainGroupId} />
        <Modul1 />
        <VideoAndText />
        {/* <Video /> */}
      </div>      
        <Email />
        <Mission />
      <Contact />
    </div>
  );
}

export default App;
