//"Sonstiges"

export const jumpToElementByClass = (classname) => {
    return () => {
        const element = document.getElementsByClassName(classname)[0];
        if (element) {
            element.scrollIntoView();
            element.focus();
        }
    }
}

export const jumpToElementById = (id) => {
    return () => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView();
            element.focus();
        }
    }
}
