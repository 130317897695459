import React, { useState } from "react";
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import "./SuccessDialog.css";
import { useTranslation } from "react-i18next";

function SuccessDialog(props) {
    const [show, setShow] = useState(true);
    const { t, i18n } = useTranslation();
  
    const handleClose = () => setShow(false);

    return (
      <>  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t("successdialog-title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.text}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("successdialog-close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default SuccessDialog;