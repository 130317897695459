import React from "react";
import { ReactComponent as SocratesHeader } from "./elements/SocratesHeader.svg";
import { useTranslation } from "react-i18next";
import ShareButton from "./ShareButton";
import "./Header.css";
import i18n from '../i18n';

const changeLanguage = (ln) => {
    return () => {
        console.log(`Language changes to ${ln}`);
        i18n.changeLanguage(ln)
    }
}

function Header() {
    const { t, i18n } = useTranslation();

    return (
        <div className="header">
            <div className="header-first-container">
                <div className="logo">
                    < SocratesHeader className="socrates-logo" />
                </div>
                <div className="top-right-container">
                    <div className="languages">
                        <a onClick={changeLanguage("de")}>DE</a>
                        <h3> / </h3>
                        <a onClick={changeLanguage("en")}>EN</a>
                    </div>
                    <ShareButton className="share-button" />
                </div>
            </div>

            
        </div>
    );
}

export default Header;

