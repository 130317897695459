import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';
import "./SocialIcon.css";

function SocialEmail() {
  
    const { t, i18n } = useTranslation();
    const handleClick = () => {

        var url="mailto:";
        url+="?subject=" + encodeURI(t('socialicons-email-subject'));
        url+="&body=" + encodeURI(t('socialicons-email-body'));
        window.open(url, '_blank').focus();
    }
  
    return (
      <Button variant="primary" onClick={handleClick}>
        <div className="resp-sharing-button resp-sharing-button--email resp-sharing-button--medium">
            <div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solidcircle">                
                <img className="mail-icon" src="images/share-btn/icon_ionic-ios-mail.png" />              
            </div>
            E-MAIL
        </div>
      </Button>
    );
}
  
export default SocialEmail;