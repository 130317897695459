import React from 'react';
import ReactDOM from 'react-dom/client';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';

import App from './App'; 
import Imprint from './components/Imprint'; 
import Policy from './components/PrivacyPolicy'; 

import './i18n';
import './index.css';


const RoutedApp = () => {
  return(
    <Router>
      <Routes>
        <Route exact path="/" element={<App/>}/>        
        <Route exact path="/imprint" element={<Imprint/>}/>
        <Route exact path="/policy" element={<Policy/>}/>
      </Routes>
    </Router>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RoutedApp />
  </React.StrictMode>
);

