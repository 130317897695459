import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRight } from "./elements/rehub_socrates_arrow_right_la1.svg";
import { Button, Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css'
import 'animate.css';
import './VideoAndText.css'

function VideoAndText() {
    const { t, i18n } = useTranslation();

    return (
        <Container className="video-and-text-container">
            <Row className="video-and-text">
                <Col>
                    <video
                        className="video-slides"
                        src="/images/carousel/SolarPan_v01(0.7).mp4"
                        muted
                        autoPlay
                        playsInline
                        loop
                        alt="All the devices"
                    />
                </Col>
                <Col className="bulletpoints first">
                    <ArrowRight className="arrow-videos" />
                    <h3>{t("video-side-main-1")}</h3>
                    {t("video-side-sub-1")}<br />
                    <hr/>
                    <ul>
                        <li>{t("video-side-bulletpoint-1.1")}</li>
                        <li>{t("video-side-bulletpoint-1.2")}</li>
                        <li>{t("video-side-bulletpoint-1.3")}</li>
                    </ul>
                </Col>
            </Row>
            <Row className="video-and-text second">
                <Col className="bulletpoints second">
                    <ArrowRight className="arrow-videos" />
                    <h3>{t("video-side-main-2")}</h3>
                    {t("video-side-sub-2")}<br />
                    <hr/>
                    <ul>
                        <li>{t("video-side-bulletpoint-2.1")}</li>
                        <li>{t("video-side-bulletpoint-2.2")}</li>
                        <li>{t("video-side-bulletpoint-2.3")}</li>
                    </ul>
                </Col>
                <Col>
                    <video
                        className="video-slides"
                        src="/images/carousel/WF_03F(x1.5).mp4"
                        muted
                        autoPlay
                        playsInline
                        loop
                        alt="All the devices"
                    />
                </Col>
            </Row>
            <Row className="video-and-text">
                <Col>
                    <video
                        className="video-slides"
                        src="/images/carousel/WF_01F(x2).mp4"
                        muted
                        autoPlay
                        playsInline
                        loop
                        alt="All the devices"
                    />
                </Col>
                <Col className="bulletpoints third">
                    <ArrowRight className="arrow-videos" />
                    <h3>{t("video-side-main-3")}</h3>
                    {t("video-side-sub-3")}<br />
                    <hr/>
                    <ul>
                        <li>{t("video-side-bulletpoint-3.1")}</li>
                        <li>{t("video-side-bulletpoint-3.2")}</li>
                        <li>{t("video-side-bulletpoint-3.3")}</li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}

export default VideoAndText;