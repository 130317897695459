import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';
import { Popover } from 'react-bootstrap';
import { Overlay } from 'react-bootstrap';

import SocialEmail from "./socialicons/SocialEmail";
import SocialLinkedin from "./socialicons/SocialLinkedin";
import SocialWhatsapp from "./socialicons/SocialWhatsapp";
import SocialTelegram from "./socialicons/SocialTelegram";

import "./ShareButton.css";

/**
 * The scripts based on the work of 
 * https://sharingbuttons.io/
 * https://github.com/mxstbr/sharingbuttons.io
 * MIT Licence
 */

function ShareButtonFooter() {
    const [show, setShow] = useState(false);
    const [placementType, setPlacementType] = useState("left");
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const { t, i18n } = useTranslation();
  
    const handleClick = (event) => {
      setShow(!show);
      setTarget(event.target);
      handleOverlayPlacement();
    };

    const handleOverlayPlacement = () => {
      setPlacementType("bottom");
      console.log(window.screen.width);
      const widthSize = window.screen.width;
      if (widthSize > 480) {
        setPlacementType("left");
      } else {
        setPlacementType("right");
      }
    };
  
    return (
      <div ref={ref}>
        <Button onClick={handleClick}>
            <span>{t("share")}</span>
            <img src="/images/header/rehub_socrates_icon_share_white_la1.png" />
        </Button>
  
        <Overlay className="socialicons-overlay"
          show={show}
          target={target}
          placement={placementType}
          container={ref}
          containerPadding={20}
          rootClose
          onHide={() => setShow(false)}
        >
          <Popover id="popover-contained">
            <Popover.Body className="socialicons-body">
                <SocialEmail className="socialicons-email" />
                <SocialLinkedin className="socialicons-linkedin" />
                <SocialWhatsapp className="socialicons-whatsapp" />
                <SocialTelegram className="socialicons-telegram" />
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    );
  }
  
export default ShareButtonFooter;