import React from "react";
import { useTranslation } from "react-i18next";
import "./Header.css";
import { jumpToElementByClass } from "./Util";
import { Container, Row, Col } from 'react-bootstrap';


function FrontPage(){
    
    const { t, i18n } = useTranslation();
    return(
        <div className="header-second-container">
                <div className="info">
                    <h1 className="info-first-part"> {t("info-1")} </h1>
                    <h1 className="info-second-part"> {t("info-2")} </h1>
                    <p>{t("info-3")}</p>
                    <button onClick={jumpToElementByClass('email-registration')}>
                        <span>{t("signin-btn")} <i>beta</i> SOCRATES 1.7</span>
                    </button>
                    <hr style={{
                        height: 3,
                    }} />
                    <div className="partners">
                        <div className="partner-proptech">
                            <p><span>{t("partner-2")}</span>&nbsp;<img src="/images/header/PropTech-Powerhouse-logo-s-w.png" /></p>
                        </div>
                        <div className="partner-detail">
                            <p>{t("partner-1")}</p>
                        </div>
                    </div>
                </div>
                <div className="header-video">
                    <video
                        style={{ margin: "0 auto" }}
                        src="/images/header/Anim_3.2_x0.35.mp4"
                        muted
                        autoPlay
                        playsInline
                        loop
                        alt="All the devices"
                    />
                </div>
            </div>
    )
}

export default FrontPage;

